// External packages
import { graphql } from "gatsby"
import React from "react"
import styled from "@emotion/styled"

// Components
import Layout from "../components/layouts"
import ContentModules from "../components/Page/ContentModules"
import SEO from "../components/seo"
import { ShopTheLook } from "../components/v2/ShopTheLook"
import { getFaqSchema } from "../utils/seo/getFaqSchema"
import { Page } from "../components/v2/contentful/Page"
import {
  BreadcrumbsListItem,
  ContentfulGrid,
  ContentfulPage,
  ContentfulShopTheLook,
} from "types"

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.grayscale[100]};

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    padding-top: 50px;
  }
`
interface PageProps {
  data: {
    page: ContentfulPage
  }
  pageContext: {
    slug: string
    locale: string
    shopstoryContent: any
    shopTheLookModules: ContentfulShopTheLook[]
    gridModules: ContentfulGrid[]
    seo: {
      breadcrumbsList: BreadcrumbsListItem[]
    }
  }
}

const PageTemplate = ({ data, pageContext }: PageProps) => {
  let { page } = data
  let shopTheLookItems = pageContext.shopTheLookModules
  let faqSchema = null

  // TODO: Revisit this logic when the FAQ page is migrated to new modules
  if (pageContext.slug === "frequently-asked-questions") {
    faqSchema = getFaqSchema(
      // @ts-ignore
      page.contentModules[0].text?.raw,
      // @ts-ignore
      page.contentModules[0]?.text.references
    )
  }

  // Combined enriched grid modules with other ones
  const modules = page.contentModules
    ?.filter((i) => i.internal?.type)
    .map((module) => {
      const grid = pageContext.gridModules?.find(
        (grid) =>
          grid.contentful_id === (module as ContentfulGrid).contentful_id
      )

      if (grid) {
        return grid
      }

      return module
    })

  const pageData = {
    ...page,
    contentModules: modules,
  }

  return (
    <Layout
      pullUp={page.isNavbarOverlapping}
      hidePromotional={page.hidePromotionalElement}
      isSizeGuide={page.pageTitle === "Size Guide" ? true : false}
    >
      <SEO
        metaImage={page.metaImage && page.metaImage.file.url}
        title={page.metaTitle || page.pageTitle}
        description={page.metaDescription?.metaDescription}
        canonicalUrl={`/${pageContext.slug}`}
        schema={faqSchema}
        breadcrumbsList={pageContext.seo?.breadcrumbsList}
      />
      <Container>
        {page.contentModules?.length && shopTheLookItems?.length > 0 ? (
          <ShopTheLook
            items={shopTheLookItems}
            pageTitle={page.pageTitle}
            pageDescription={page.pageDescription}
            hasTopGrid={page.hasTopGrid}
          />
        ) : (
          <>
            {pageData.contentModules?.length > 0 && <Page data={pageData} />}
            {/* TODO: Delete ContentModules component when content is migrated to new modules */}
            <ContentModules
              pullUp={page.isNavbarOverlapping}
              allModules={page.contentModules}
              shopstoryContent={pageContext.shopstoryContent}
            />
          </>
        )}
      </Container>
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query PageQuery($slug: String!, $locale: String) {
    page: contentfulPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      ...PageContent
    }
  }
`
